import React from "react";
import Layout from "components/layout";
import { withI18next } from "lib/withI18next";
import SearchBlock from "components/search/SearchBlock";
import { ApolloProvider, Query } from "react-apollo";
import gql from "graphql-tag";
import client from "lib/ApolloClient";
import { autobind } from "react-decoration";
import { page } from "components/page";
import navigate from "lib/navigate";

const getQuestionnaireDetail = gql`
  query getQuestionnaireDetail($form: SearchForm) {
    getQuestionnaireDetail(Input: $form) {
      sn
      subject
      content
      onlinedate
      offlinedate
      detail {
        sn
        title
        content
        type
        option
        required
        isbr
      }
    }
  }
`;

const addQuestionnaire = gql`
  mutation addQuestionnaire($form: QuestionnaireForm) {
    result: addQuestionnaire(Input: $form) {
      success
      message
    }
  }
`;

@withI18next(["common"])
@page
class questionnaireDetail extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      resourceType: "databases",
      pageContent: "",
      pradio: {},
      pcheckbox: {},
      ptext: {},
      ptextarea: {},
      pothertext: {},
    };
  }

  @autobind
  handleChange(e) {
    alert(e.target.name);
    alert(e.target.value);

    //console.log(this.state[e.target.name]);

    Object.keys(this.state).map((key) => {
      console.log(key);
      return "";
    });
  }

  @autobind
  handlePtextChange(e) {
    //alert(e.target.name);
    //alert(e.target.value);
    this.state.ptext[e.target.name] = e.target.value;
  }

  @autobind
  handlePtextareaChange(e) {
    //alert(e.target.name);
    //alert(e.target.value);
    this.state.ptextarea[e.target.name] = e.target.value;
  }

  @autobind
  handlePradioChange(e) {
    //alert(e.target.name);
    //alert(e.target.value);
    this.state.pradio[e.target.name] = e.target.value;
  }

  @autobind
  handlePcheckboxChange(e) {
    //alert(e.target.name);
    //alert(e.target.value);
    //alert(e.target.checked);
    let data = this.state.pcheckbox[e.target.name];
    console.log(data);

    data[e.target.value] = e.target.checked;
    this.state.pcheckbox[e.target.name] = data;
  }

  @autobind
  handlePothertextChange(e) {
    //alert(e.target.name);
    //alert(e.target.value);
    this.state.pothertext[e.target.name] = e.target.value;
  }

  @autobind
  handleSubmit(e) {
    e.preventDefault();
    //console.log(this.state);

    let postdata = [];

    //198	46	144	文字標題	20210302151750	hyint	NULL	NULL	98A3E66D2A62303C3CFDB7C02945C94C
    //199	46	145	段落標題	20210302151750	hyint	NULL	NULL	98A3E66D2A62303C3CFDB7C02945C94C
    //200	46	146	單選2	20210302151750	hyint	NULL	NULL	98A3E66D2A62303C3CFDB7C02945C94C
    //201	46	147	核取方塊1	20210302151750	hyint	NULL	NULL	98A3E66D2A62303C3CFDB7C02945C94C
    //202	46	148	核取1,核取2,核取3,核取方塊其他2	20210302151750	hyint	NULL	NULL	98A3E66D2A62303C3CFDB7C02945C94C
    //203	46	149	5	20210302151750	hyint	NULL	NULL	98A3E66D2A62303C3CFDB7C02945C94C
    //204	46	150		20210302151751	hyint	NULL	NULL	98A3E66D2A62303C3CFDB7C02945C94C

    Object.keys(this.state.ptext).map((key) => {
      //console.log(key);
      postdata[postdata.length] = key + "@@@" + this.state.ptext[key];
      return "";
    });

    Object.keys(this.state.ptextarea).map((key) => {
      //console.log(key);
      postdata[postdata.length] = key + "@@@" + this.state.ptextarea[key];
      return "";
    });

    Object.keys(this.state.pradio).map((key) => {
      //console.log(key);
      postdata[postdata.length] = key + "@@@" + this.state.pradio[key];
      return "";
    });

    Object.keys(this.state.pcheckbox).map((key) => {
      let str = "";
      //console.log("key="+key);
      let data = this.state.pcheckbox[key];
      Object.keys(data).map((key2) => {
        console.log("key2=" + key2);
        let data2 = data[key2];
        //console.log(data2);

        if (key2 === "其他" && data2) {
          str +=
            str === ""
              ? this.state.pothertext[key + "_other"]
              : "," + this.state.pothertext[key + "_other"];
        } else if (data2) {
          str += str === "" ? key2 : "," + key2;
        }
        //console.log("str1="+str);
        return "";
      });
      //console.log("str2="+str);
      postdata[postdata.length] = key + "@@@" + str;
      return "";
    });

    this.formContentStr = postdata.join("|||");
    console.log(this.formContentStr);

    this.addQuestionnaire();
  }

  @autobind
  async addQuestionnaire() {
    //let a = this.state.formData
    //delete a["username"]

    let data = {};
    data.sn = this.props.search.id;
    data.content = this.formContentStr;

    let result = await client.jumperrwdClient
      .mutate({
        mutation: addQuestionnaire,
        variables: {
          form: data,
        },
      })
      .then((data) => {
        return data;
      })
      .finally(() => {
        return null;
      });

    if (result.data.result.success) {
      alert("填寫完成！");
      navigate("/questionnaire");
    } else {
      alert("填寫失敗！");
    }
  }

  render() {
    /*let startdate = this.state.pageContent.registerSdate
      ? this.state.pageContent.registerSdate.substring(0, 4) +
        "-" +
        this.state.pageContent.registerSdate.substring(4, 6) +
        "-" +
        this.state.pageContent.registerSdate.substring(6, 8) +
        " " +
        this.state.pageContent.registerSdate.substring(8, 10) +
        ":" +
        this.state.pageContent.registerSdate.substring(10, 12)
      : "";
    let enddate = this.state.pageContent.registerEdate
      ? this.state.pageContent.registerEdate.substring(0, 4) +
        "-" +
        this.state.pageContent.registerEdate.substring(4, 6) +
        "-" +
        this.state.pageContent.registerEdate.substring(6, 8) +
        " " +
        this.state.pageContent.registerEdate.substring(8, 10) +
        ":" +
        this.state.pageContent.registerEdate.substring(10, 12)
      : "";*/
    //console.log("startdate="+startdate);
    //console.log("enddate="+enddate);

    return (
      <Layout {...this.props}>
        <div className="main sp" id="center">
          <SearchBlock />
          <section className="main_content">
            <div className="container">
              <a
                className="accesskey"
                href="javascript:;"
                id="aC"
                accessKey="C"
                title={this.props.t("jumperrwd.common.mainContenctArea")}>
                :::
              </a>
              <h2>{this.props.t("hyint.common.questionnaire")}</h2>
              <a href="javascript:history.back();" className="back">
                {this.props.t("hyint.common.table.back")}
              </a>

              <ApolloProvider client={client.jumperrwdClient}>
                <Query
                  displayName="graphql"
                  query={getQuestionnaireDetail}
                  variables={{
                    form: {
                      id: this.props.search.id,
                    },
                  }}>
                  {({ loading, data, refetch, error }) => {
                    if (error) return "error";
                    if (loading) return "Loading...";

                    if (
                      data.getQuestionnaireDetail !== null &&
                      data.getQuestionnaireDetai !== undefined
                    ) {
                      return (
                        <div className="detail_block">
                          <div className="info">
                            <h3>{data.getQuestionnaireDetail.subject}</h3>
                            <ul>
                              <li>
                                <span>
                                  {this.props.t(
                                    "hyint.admin.common.table.desc"
                                  )}
                                  {this.props.t("hyint.common.table.colon")}
                                </span>
                                {data.getQuestionnaireDetail.content}
                              </li>
                              <li>
                                <span>調查起訖時間：</span>
                                {data.getQuestionnaireDetail.onlinedate} ~{" "}
                                {data.getQuestionnaireDetail.offlinedate}
                              </li>
                            </ul>
                          </div>

                          <div className="questionnaire">
                            <form onSubmit={this.handleSubmit}>
                              <div className="form_grid">
                                {data.getQuestionnaireDetail.detail.map(
                                  (item, i) => {
                                    let sn = "answer" + item.sn;

                                    if (item.type === "section") {
                                      return <h3>{item.title}</h3>;
                                    } else if (item.type === "text") {
                                      this.state.ptext[sn] = "";

                                      return (
                                        <div className="form_grp">
                                          <label
                                            htmlFor={sn}
                                            className="form_title">
                                            {item.title}
                                            <abbr
                                              className="necessary"
                                              title={this.props.t(
                                                "jumper.common.contact.mistinfo"
                                              )}>
                                              *
                                            </abbr>
                                          </label>
                                          <div className="form_content">
                                            <label htmlFor={sn}>
                                              {item.content}
                                            </label>
                                            <input
                                              type="text"
                                              id={sn}
                                              name={sn}
                                              required={
                                                item.required === 1
                                                  ? "required"
                                                  : ""
                                              }
                                              onChange={this.handlePtextChange}
                                            />
                                          </div>
                                        </div>
                                      );
                                    } else if (item.type === "textarea") {
                                      this.state.ptextarea[sn] = "";

                                      return (
                                        <div className="form_grp">
                                          <label
                                            htmlFor="address"
                                            className="form_title">
                                            {item.title}
                                          </label>
                                          <div className="form_content">
                                            {item.content}
                                            <textarea
                                              name={sn}
                                              cols="30"
                                              rows="10"
                                              required={
                                                item.required === 1
                                                  ? "required"
                                                  : ""
                                              }
                                              onChange={
                                                this.handlePtextareaChange
                                              }></textarea>
                                          </div>
                                        </div>
                                      );
                                    } else if (item.type === "radio") {
                                      let options = item.option.split("::");

                                      return (
                                        <div className="form_grp">
                                          <div className="form_title">
                                            {item.title}
                                            <abbr
                                              className="necessary"
                                              title={this.props.t(
                                                "jumper.common.contact.mistinfo"
                                              )}>
                                              *
                                            </abbr>
                                          </div>
                                          <div className="form_content">
                                            <div className="radio_grp form_inline">
                                              {item.content}
                                              {options.map((option, i) => {
                                                let id = "select" + i;
                                                return (
                                                  <>
                                                    <input
                                                      type="radio"
                                                      id={id}
                                                      name={sn}
                                                      value={option}
                                                      required={
                                                        item.required === 1
                                                          ? "required"
                                                          : ""
                                                      }
                                                      onChange={
                                                        this.handlePradioChange
                                                      }
                                                    />
                                                    <label htmlFor={id}>
                                                      {option}
                                                    </label>
                                                  </>
                                                );
                                              })}
                                            </div>
                                          </div>
                                        </div>
                                      );
                                    } else if (item.type === "checkbox") {
                                      let options = item.option.split("::");

                                      let checkboxname = "answer" + item.sn;

                                      let data = {};
                                      for (i = 0; i < options.length; i++) {
                                        data[options[i]] = false;
                                      }

                                      this.state.pcheckbox[checkboxname] = data;
                                      //console.log(this.state)

                                      return (
                                        <div className="form_grp">
                                          <div className="form_title">
                                            {item.title}
                                            <abbr
                                              className="necessary"
                                              title={this.props.t(
                                                "jumper.common.contact.mistinfo"
                                              )}>
                                              *
                                            </abbr>
                                          </div>
                                          <div className="form_content">
                                            <div className="radio_grp form_inline">
                                              {item.content}
                                              {options.map((option, i) => {
                                                if (option === "其他") {
                                                  //let name = "answer" + item.sn;
                                                  let othername =
                                                    "answer" +
                                                    item.sn +
                                                    "_other";
                                                  return (
                                                    <>
                                                      <input
                                                        type="checkbox"
                                                        title={"選擇" + option}
                                                        name={sn}
                                                        value={option}
                                                        onChange={
                                                          this
                                                            .handlePcheckboxChange
                                                        }
                                                      />
                                                      <label
                                                        htmlFor={othername}>
                                                        {option}
                                                      </label>
                                                      <input
                                                        type="text"
                                                        id={othername}
                                                        name={othername}
                                                        onChange={
                                                          this
                                                            .handlePothertextChange
                                                        }
                                                      />
                                                    </>
                                                  );
                                                } else {
                                                  //let name = "answer" + item.sn;
                                                  return (
                                                    <>
                                                      <input
                                                        type="checkbox"
                                                        id={sn}
                                                        name={sn}
                                                        value={option}
                                                        onChange={
                                                          this
                                                            .handlePcheckboxChange
                                                        }
                                                      />
                                                      <label htmlFor={sn}>
                                                        {option}
                                                      </label>
                                                    </>
                                                  );
                                                }
                                              })}
                                            </div>
                                          </div>
                                        </div>
                                      );
                                    } else if (item.type === "level") {
                                      //1::start::5::end
                                      let options = item.option.split("::");

                                      let start = parseInt(options[0]);
                                      let end = parseInt(options[2]);
                                      let startTip = options[1];
                                      let endTip = options[3];

                                      let lists = [];
                                      //lists.push(startTip + "{'\u00A0'}")
                                      for (let i = start; i <= end; i++) {
                                        //let name = "answer" + item.sn;
                                        lists.push(i);
                                        lists.push(
                                          <input
                                            type="radio"
                                            title={"選擇" + i}
                                            name={sn}
                                            id={"select" + i}
                                            value={i}
                                            required={
                                              item.required === 1
                                                ? "required"
                                                : ""
                                            }
                                            onChange={this.handlePradioChange}
                                          />
                                        );
                                      }
                                      //lists.push("&nbsp;" + endTip)

                                      return (
                                        <div className="form_grp">
                                          <label
                                            htmlFor=""
                                            className="form_title">
                                            {item.title}
                                            <abbr
                                              className="necessary"
                                              title={this.props.t(
                                                "jumper.common.contact.mistinfo"
                                              )}>
                                              *
                                            </abbr>
                                          </label>
                                          <div className="form_content">
                                            {startTip}
                                            {"\u00A0"}
                                            {lists}
                                            {"\u00A0"}
                                            {endTip}
                                          </div>
                                        </div>
                                      );
                                    }
                                    return "";
                                  }
                                )}

                                <div className="btn_grp">
                                  <button
                                    type="reset"
                                    className="btn btn-reset">
                                    {this.props.t("hyint.admin.common.reset")}
                                  </button>
                                  <button
                                    type="submit"
                                    className="btn btn-submit">
                                    {this.props.t(
                                      "hyint.common.table.gosubmit"
                                    )}
                                  </button>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      );
                    } else return "Error...";
                  }}
                </Query>
              </ApolloProvider>
            </div>
          </section>
        </div>
      </Layout>
    );
  }
}

export default questionnaireDetail;
